<template>
  <div>
    <!--
    <van-button type="primary" @click="openSelecterBox">主要按钮</van-button>
    <map-block :x="36.812859" :y="118.01205" title="树林" />
    <donate-selecter :show.sync="showSelecterBox" :project="1" />
    -->
    <button @click="dosth">change</button>
    <vue-danmaku class="danmu" ref="danmu" :danmus="danmus">
      <p>sdfgh</p>
    </vue-danmaku>
  </div>
</template>

<script>
/* eslint-disable */
import DonateSelecter from "@/components/DonateSelecter";
import MapBlock from "@/components/MapBlock";
import wxSdk from "@/utils/wxsdk";
import NavigateWxmp from "@/components/NavigateWxmp";
import SlfxImage from "@/assets/images/slfx.jpg";
import vueDanmaku from "vue-danmaku";

export default {
  components: { DonateSelecter, MapBlock, NavigateWxmp, vueDanmaku },
  data() {
    return {
      danmus: ["danmu1", "danmu2", "danmu3", "..."],
      SlfxImage: SlfxImage,
      showSelecterBox: false,
      path: null,
      loaded: false,
      msg: "Hello vue-baberrage",
      barrageIsShow: true,
      currentId: 0,
      barrageLoop: false,

      images: [
        {
          src:
            "https://foundation.isdut.cn/wp-content/uploads/2020/12/2020120800211259159.jpg",
          description: "Star Night Sky Ravine by Mark Basarab, from Unsplash.",
          alt: "Blue starry night photo.",
          thumbnailWidth: "220px",
        },
        {
          src:
            "https://foundation.isdut.cn/wp-content/uploads/2020/12/2020120800444872184.jpg",
          description: "Corno Nero, Italy by Luca Zanon, from Unsplash.",
          alt: "Landscape photo of mountain with fog.",
          thumbnailWidth: "220px",
        },
      ],
      testList: [{ name: "a" }, { name: "b" }, { name: "c" }],
    };
  },
  mounted() {},
  methods: {
    openSelecterBox() {
      this.showSelecterBox = true;
    },
    dosth() {
      this.$refs["danmu"].play();
    },
  },
};
</script>

<style>
.danmu {
  width: 100%;
  height: 100px;
  background: linear-gradient(45deg, #5ac381, #20568b);
}
</style>
